import clsx from 'clsx';
import NextLink from 'next/link';
import { ReactNode } from 'react';

import { SBImage } from '@/components/ImageService/SBImage';
import styles from './RichTextComponents.module.scss';

export const Bold = (children: ReactNode) => <b className={styles.bold}>{children}</b>;
export const Italic = (children: ReactNode) => <i className={styles.italic}>{children}</i>;

export const Link = (
    children: ReactNode,
    { linktype, href, target }: { linktype?: string; href?: string; target?: string },
) => {
    if (linktype === 'email') {
        return <a href={`mailto:${href}`}>{children}</a>;
    }
    if (href) {
        return (
            <a href={href} target={target}>
                {children}
            </a>
        );
    }
    return <NextLink href={href || ''}>{children}</NextLink>;
};

export const Paragraph = (children: ReactNode) => <p className={clsx(styles.pararaph)}>{children}</p>;
export const Ol = (children: ReactNode) => <ol className={styles.olRte}>{children}</ol>;
export const Ul = (children: ReactNode) => <ul className={styles.ulRte}>{children}</ul>;
export const Br = () => <br className={styles.BrRte} />;
export const ImageRte = (children: any, props: any) => {
    return (
        <span className={styles.imageWrapper}>
            <SBImage
                image={{
                    filename: props.src,
                    id: props.id,
                    name: props.name,
                    copyright: props?.copyright,
                    alt: props?.alt,
                    title: props?.title,
                }}
                fit="cover"
                height={0}
                width={0}
                className={styles.image}
            />
        </span>
    );
};
