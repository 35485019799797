import clsx from 'clsx';
import {
    MARK_BOLD,
    MARK_ITALIC,
    MARK_LINK,
    NODE_BR,
    NODE_IMAGE,
    NODE_OL,
    NODE_PARAGRAPH,
    NODE_UL,
    render,
} from 'storyblok-rich-text-react-renderer';

import { RichtextStoryblok } from '@/shared/types/storyblok/storyblok-types';
import { Bold, Br, ImageRte, Italic, Link, Ol, Paragraph, Ul } from './components/RichTextComponents';
import styles from './components/RichTextComponents.module.scss';

type RichTextProps = {
    document: RichtextStoryblok | undefined;
    classNameRoot?: string;
};

const RichText: React.FC<RichTextProps> = ({ document, classNameRoot }) => {
    if (!document) {
        return null;
    }

    return (
        <div className={clsx(styles.rte, classNameRoot)}>
            {render(document, {
                markResolvers: {
                    [MARK_BOLD]: Bold,
                    [MARK_LINK]: Link,
                    [MARK_ITALIC]: Italic,
                },
                nodeResolvers: {
                    [NODE_PARAGRAPH]: node => Paragraph(node),
                    [NODE_IMAGE]: ImageRte,
                    [NODE_OL]: Ol,
                    [NODE_UL]: Ul,
                    [NODE_BR]: Br,
                },
            })}
        </div>
    );
};

export default RichText;
