import { useThemeSection } from '@/shared/providers/SectionContext';
import { UniversalTextBlockStoryblok } from '@/shared/types/storyblok/storyblok-types';
import clsx from 'clsx';
import Container from '../Container/Container';
import RichText from '../RichText/RichText';
import styles from './UniversalTextBlock.module.scss';

const UniversalTextBlock = (data: UniversalTextBlockStoryblok) => {
    const description = data.blok?.description;
    const title = data.blok?.title;
    const standalone = data.blok?.spacing === '' || data.blok?.spacing === 'standalone';
    const accordion = data.blok?.spacing === 'accordion';
    const theme = useThemeSection();
    const darkTheme = theme === 'Dark';

    if (accordion) {
        return (
            <Container code="standard" className={{ wrap: clsx(styles.accordion_container) }}>
                <div className={clsx(darkTheme ? styles.dark : styles.light)}>
                    {title && <h3>{title}</h3>}
                    <RichText document={description} classNameRoot={clsx(styles.rte, title && styles.title)} />
                </div>
            </Container>
        );
    } else if (standalone) {
        return (
            <div className={clsx(darkTheme ? styles.container_dark : styles.container, styles.content)}>
                <div className={styles.standalone}>
                    {title && <h2>{title}</h2>}
                    <RichText
                        document={description}
                        classNameRoot={clsx(styles.rte, title && styles.title, darkTheme ? styles.dark : styles.light)}
                    />
                </div>
            </div>
        );
    }
    return (
        <Container
            blok={data.blok}
            code="standard"
            className={{ wrap: clsx(darkTheme ? styles.container_dark : styles.container, styles.buzzword_container) }}
        >
            <div className={clsx(darkTheme ? styles.dark : styles.light)}>
                {title && <h3>{title}</h3>}
                <RichText document={description} classNameRoot={clsx(title && styles.title, styles.rte)} />
            </div>
        </Container>
    );
};
export default UniversalTextBlock;
